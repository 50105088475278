import { mainnet, goerli, sepolia, baseSepolia, base } from 'wagmi/chains'
import { Chain } from 'wagmi'


export function abbreviateAddress(address: string, charsLength: number = 4): string {
  if (typeof address !== 'string') return '';
  if (address.length < charsLength * 2 + 2) return address;

  const prefix = address.substr(0, charsLength + 2); // '0x' + charsLength characters
  const suffix = address.substr(-charsLength);

  return `${prefix}...${suffix}`;
}

//export const chain = base; // <-- MAINNET SET LIVE
export const chain = baseSepolia; // testnet

console.log(`Current chain: ${chain.network}`);

const getEtherScan = () => {
  switch (chain.network) {
    case "base":  return "https://basescan.org";
    case "base-sepolia":  return "https://sepolia.basescan.org";
  }
}

export const getEtherscanAddressLink = (hash) => {
  return `${getEtherScan()}/address/${hash}`;
}

export const getEtherscanTxnLink = (hash) => {
  return `${getEtherScan()}/tx/${hash}`;
}

export const getEtherscanContractLink = (hash) => {
  return `${getEtherScan()}/address/${hash}`;
}

console.log(`Chain: ${chain.name} (${chain.id})`)
console.log(`FrontEnd: ${getEtherscanContractLink(process.env.REACT_APP_CONTRACT_FRONTEND)}`)


export const links = {
  base: "https://www.base.org/",
  twitter: "https://x.com/dailofrog",
  twitterGoop: "https://x.com/goopgoop_art",
  discord: "https://discord.gg/KBAbk2a4PM",
  contractFrontEnd: getEtherscanContractLink(process.env.REACT_APP_CONTRACT_FRONTEND),
  opensea: "https://opensea.io/collection/blastrocks/",
  tinyBasedFrogs:  "https://www.tinybasedfrogs.xyz/",
  memoryBlocks: "https://www.memoryblocks.art/",
  linkTree: "https://linktr.ee/dailofrog",
};
