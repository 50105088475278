// src/StyledComponents.js
import styled, { keyframes }  from 'styled-components';


const mobileBreakpoint = '768px';

export const UnicodeIcon = styled.span`
  font-family:inherit;
`;

export const IconLogo = ()=>{
  return (
      <UnicodeIcon>🌼</UnicodeIcon>
  )
};

export const IconSquare = ()=>{
  return (
      <UnicodeIcon>▪</UnicodeIcon>
  )
};


export const Navbar = styled.nav`
  background-color: var(--yellow-300);
  z-index:999;
  position: relative;
  padding: 1em;
  display: flex;
  align-items: center;



  h1 {
    color:black;
  }

  h1 a {
    color:black;
    font-family:"Header";
  }

`;

export const HiddenMobile = styled.span`

 @media (max-width: ${mobileBreakpoint}) {
    display: none; // hide the component on mobile screens
  }
`;

export const ShowMobile = styled.span`

 @media (min-width: ${mobileBreakpoint}) {
    display: none; // hide the component on mobile screens
  }
`;

export const NavbarLinks = styled.span`
  display: flex; // To layout the links horizontally
  margin-left: auto; // Push the span to the far right
  color: #777;


  a {
    font-weight:bold;
    font-family: "Header";
    color: black;
    text-decoration: none;
    margin-left: 0.75em;
    margin-right: 0.75em;
    text-transform:uppercase;
  }

  a:hover {
    text-decoration: underline;
  }

`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; // Center the columns if they are less than the container's width
`;

export const Break = styled.div`
  height: 1em;
`;

export const Section = styled.div`
  /*background-color: ${props => props.bg || '#ccc'};*/
  position: relative;
  color: black;
`;

export const HeroContainer = styled.div`

    display: flex; /* Enables Flexbox */
    flex-direction: row; /* Aligns children in a row */
    justify-content: center; /* Centers content horizontally */

    flex-wrap: wrap; /* Allows items to wrap onto multiple lines */

    h1 {
      font-size: 3em;
    }

    h2 {
      color: #B29693;
      position:relative;
      bottom:-0.5em;
    }
`

export const FixedHeightContainer = styled.div`
    height: ${props => props.height || '90vh'};
    min-height: fit-content;
    max-height: 3500px;
    display: flex; /* Enables Flexbox */
    flex-direction: row; /* Aligns children in a row */
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */

    flex-wrap: wrap; /* Allows items to wrap onto multiple lines */
`;

export const Column = styled.div`
  width:40vw;
  margin: 0 1em;
`;


export const ColumnHeroImage = styled.div`
  width:40vw;
  max-width:400px;
  margin: 0 1.5em;

  @media (max-width: ${mobileBreakpoint}) {
     width:80vw;
     //display: none;
  }
`;

export const ColumnHeroDesc = styled.div`
  width:60vw;
  max-width:550px;
  margin: 0 1.5em;
  text-align:center;

  p {
    margin-bottom: 1em;
  }

  @media (max-width: ${mobileBreakpoint}) {
     width:100vw;
  }
`;

export const TextWhite = styled.span`
  color: var(--white);
`;

export const TextHighlight = styled.span`
  color: var(--white);
`;

export const ColumnGameDetails = styled.div`
  width:60vw;
  max-width:600px;
  margin: 0 1em;
  text-align:center;

  h2 {
    margin-bottom: 1em;
    font-size: 2em;
  }

  p {
    margin-bottom: 1em;
  }

   @media (max-width: ${mobileBreakpoint}) {
     width:100vw;
  }
`;

export const TitleWithSeparator = styled.h2`
  font-size: 2em;
  text-align: center;
  position: relative;
  width: 90vw;

  padding: 0.5em;
  box-sizing: border-box;
  margin-top: 2.5em;
  margin-bottom: 0.5em;
  
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0.5px;
    background-color: var(--hr);
    transform: translateY(-50%);
    z-index: -1;
  }

  div {
    background-color: black;
    padding: 0 0.8em;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 1em;
  }
`

export const OnchainMechanics = styled.div`
  width:100vw;
  max-width: 900px;
  text-align:center;
  display: flex;
  flex-direction: row;
  justify-content: center;


  @media (max-width: ${mobileBreakpoint}) {
    width:100vw;
    flex-direction: column;
  }
`;

export const ColumnOnchainThirds = styled.div`
  width:30vw;
  max-width:600px;
  text-align:center;
  flex: 1 1 30%;
  margin-left: 1em;
  margin-right: 1em;

  p {
  }



  @media (max-width: ${mobileBreakpoint}) {
    width:90vw;
    flex-basis: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3em;
  }
`;

export const OnchainHeaderWithSeparator = styled.h3`
  font-size: 1em;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  text-transform: uppercase;
  color: var(--yellow);

  margin-top: -0.25em;
  margin-bottom: 1em;
  
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0.5px;
    background-color: var(--hr);
    transform: translateY(-50%);
    z-index: -1;
  }

  div {
    background-color: black;
    padding: 0 0.4em;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`

export const TopHeader = styled.div`
  text-align: center;
  padding: 0.8em 0;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin: 0 0.4em;
  }
`;



export const Footer = styled.div`
  text-align:center;
  border-top: 1px solid #b45252;
  width:90vw;
  padding: 0.75em;
  margin-top: 3em;

  p {
    color: #333;
  }

`;


export const FAQ = styled.div`
  width:70vw;
  max-width:750px;
  margin: 0 1em;

  h2 {
    font-size: 2em;
    text-align:center;
  }

   @media (max-width: ${mobileBreakpoint}) {
     width:100vw;
  }
`;

export const FAQItem = styled.div`

  margin: 0 0 4em 0; 

  p {
    margin-bottom: 0.75em;
  }

  h3 {
    text-transform: uppercase;
    margin-bottom: 0.6em;
    line-height: 1.1em;
  }

   @media (max-width: ${mobileBreakpoint}) {
     width:90vw;
  }
`;

export const MintDetails = styled.div`
  max-width: 95%;
  text-align:center;
  //background-color: orange;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; // Changed from space-around to space-between for exact spacing in 3 columns
  align-content: space-around;

  @media (max-width: ${mobileBreakpoint}) {

  }
`;

export const MintDetailsItem = styled.div`
  text-align: left;
  width: calc(33.33% - 1em); // Adjusted to fit 3 columns within the container
  flex: 1 1 calc(33.33% - 1em); // Adjusted flex basis to fit 3 columns
  margin-bottom: 1.5em;

  h3 {
    color: var(--tan);
  }
  p {
    color: var(--white);
  }

  @media (max-width: ${mobileBreakpoint}) {

  }
`;



export const Button = styled.button`
  background-color:#ffffe1;
  color: black;
  border: none;
  padding: 0.2em 1em;
  clip-path: polygon(3% 0%, 97% 0%, 100% 50%, 97% 100%, 3% 100%, 0% 50%);
  width: 70%;
  margin: 0.25em 0;


    &:hover {
      // Add your hover styles here
      background-color: var(--yellow); // Replace with the desired background color
      color: black; // Replace with the desired text color
       cursor: pointer; 
    }

    &:disabled {
      color: #333; // Replace with the desired text color
      background-color: var(--camo-300);
       cursor: not-allowed;
    }

`;



export const FAQQuestion = styled.div`
  color: black;
  margin: 3em 0;

  h2 {
    margin-bottom: 0.5em;
  }

  p {
    margin-bottom: 1em;
  }

  h2 {
    color: black;
    padding: 0.5em 0;
  }

  a {
    color: var(--mars-300);
  }

`;


export const ResponsiveImage = styled.img`
  width: 100%; // Make image take full width of the container
  height: auto; // Keep image aspect ratio
  //max-width: 500px; // Maximum width of the image
/*
  // Add a media query for larger screens
  @media (min-width: 768px) {
    max-width: 100%; // On larger screens, let the image take full width
  }*/
`;

export const HeaderImage = styled.img`
  width: 100%; // Make image take full width of the container
  height: auto; // Keep image aspect ratio
  max-height: 550px; // Maximum height of the image
  object-fit: cover; // Crop the image to fit the container
  object-position: center 60%; // Position the image around 60% from the top of the container
`;

export const ErrorMessage = styled.div`
  background-color:red;
  padding: 0.75em;
  margin-top: 0.5em;
  border: 1px solid yellow;
  word-break: break-word;
`;

export const SuccessMessage = styled.div`
  background-color:var(--lime-300);
  color:black;
  padding: 0.75em;
  margin-top: 1em;
  border-radius: 5px;
  border: 1px solid black;
  word-break: break-word;

  a {
    color: blue;
  }
`;
