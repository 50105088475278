import * as Style from "../style/StyledComponents";
import Navbar from "./Navbar";
import {Mint} from "./Mint";
import { abbreviateAddress, chain, links } from "./Config";

import imgCovenantRow from "../images/covenant/covenantrow.png";
import imgSepartor from "../images/covenant/separator2.png";
import imgHero from "../images/covenant/hero2.png";
import imgDagger from "../images/covenant/dagger2-large.png";
import imgIcon from "../images/covenant/border/BORDER1X_Animation 1_2.png";

import imgGameMechanics01 from "../images/covenant/gamedetails/website4x_Animation 1_0.png";
import imgGameMechanics02 from "../images/covenant/gamedetails/website4x_Animation 1_1.png";
import imgGameMechanics03 from "../images/covenant/gamedetails/website4x_Animation 1_2.png";

function Separator() {
	return (
		<img src={imgSepartor} alt="Decorative separator"  />
	)
}

function MintDetails() {
	return (
		<>
		<Style.ResponsiveImage src={imgDagger}/>
		<br/>
		<br/>

		<Style.MintDetails>
			<Style.MintDetailsItem>
				<h3>Mint Price:</h3>
				<p>0.02 ETH</p>
			</Style.MintDetailsItem>

			<Style.MintDetailsItem>
				<h3>Total Minted:</h3>
				<p>42069</p>
			</Style.MintDetailsItem>

			<Style.MintDetailsItem>
				<h3>Type:</h3>
				<p>Open Edition</p>
			</Style.MintDetailsItem>

			<Style.MintDetailsItem>
				<h3>Network:</h3>
				<p><a href={links.base} target="_blank">Base</a></p>
			</Style.MintDetailsItem>

			<Style.MintDetailsItem>
				<h3>Token:</h3>
				<p>ERC-721</p>
			</Style.MintDetailsItem>

			<Style.MintDetailsItem>
				<h3>Contract:</h3>
				<p><a href={links.contractFrontEnd} target="_blank">0xabcs..defs</a></p>
			</Style.MintDetailsItem>
		</Style.MintDetails>

		
		</>
	)
}

function GameDetails() {

	const numberOfDisciplesSacrified = 100;

	return (
		<>

		<Style.Container>

		<Style.TitleWithSeparator><div>Death Ritual</div></Style.TitleWithSeparator>
			
			<Style.ColumnGameDetails>

<p>You are initiated into the hollowed <Style.TextHighlight>Covenant</Style.TextHighlight> by minting a <Style.TextHighlight>Disciple</Style.TextHighlight> during the <Style.TextHighlight>Open Edition</Style.TextHighlight> phase. 65% of all minting funds (ETH) are allocated to a shared <Style.TextHighlight>Collective Pool</Style.TextHighlight>.</p>
<p>Once the Open Edition concludes, the macabre <Style.TextHighlight>Death Ritual</Style.TextHighlight> commences. Daily, {numberOfDisciplesSacrified} unfortunate Disciples are chosen at random to be sacrificed. Once sacrificed, their claim to the <Style.TextHighlight>Collective Pool</Style.TextHighlight>'s ETH is forfeited and their funds are reincorporated into the pool.</p>
<p>You possess the option at any moment to depart from the Death Ritual by either withdrawing your share of the <Style.TextHighlight>Collective Pool</Style.TextHighlight> via <Style.TextHighlight>Dishonorable Suicide</Style.TextHighlight> or revealing your Discipline via <Style.TextHighlight>Honorable Reveal</Style.TextHighlight>.</p>
<p>Alternatively, you may choose to remain in the Death Ritual, gambling on the chance of acquiring a more substantial share through time. As Disciples are sacrificed, the remaining participants' shares grow, given that the relinquished shares are redistributed among fewer survivors.</p>
<p>Will you emerge as the <Style.TextHighlight>Final Disciple</Style.TextHighlight> and seize the amassed fortune?</p>
		
		</Style.ColumnGameDetails>

		</Style.Container>
		</>
	)
}

function OnchainMechanics() {
	return (
		<>

		<Style.Container>

		<Style.TitleWithSeparator><div>Onchain Mechanics</div></Style.TitleWithSeparator>

			<div style={{textAlign:"center", width:"100%"}}><p style={{maxWidth:"600px", marginLeft:"auto", marginRight:"auto"}}>This game is 100% fully onchain on <a href={links.base} target="_blank">Base</a>.</p></div>
			
			<Style.OnchainMechanics>

			
	

		<Style.ColumnOnchainThirds>
			<Style.ResponsiveImage src={imgGameMechanics02}/>
			<Style.OnchainHeaderWithSeparator><div>Sacrificial Death</div></Style.OnchainHeaderWithSeparator>
			<p>Every few hours, a random selection of Disciples are ritualistically sacrificed. Their claim of ETH is contributed to the <Style.TextHighlight>Collective Pool</Style.TextHighlight>, and their NFT token is permanently destroyed (burned).</p>
		</Style.ColumnOnchainThirds>

		<Style.ColumnOnchainThirds>
			<Style.ResponsiveImage src={imgGameMechanics01}/>
			<Style.OnchainHeaderWithSeparator><div>Dishonorable Suicide</div></Style.OnchainHeaderWithSeparator>
			<p>You can dishonorably depart from the Covenant at any time by choosing to self-sacrifice your Disciple. This cowardice act burns your token and withdraws your portion of ETH from the shared <Style.TextHighlight>Collective Pool</Style.TextHighlight>.</p>
		</Style.ColumnOnchainThirds>

		<Style.ColumnOnchainThirds>
			<Style.ResponsiveImage src={imgGameMechanics03}/>
			<Style.OnchainHeaderWithSeparator><div>Honorable Reveal</div></Style.OnchainHeaderWithSeparator>
			<p>You can choose to honorably reveal your Disciple, forfeiting your share of the <Style.TextHighlight>Collective Pool</Style.TextHighlight> in exchange for unveiling your hooded Disciple. The fewer Disciples remain, the rarer the reveal becomes.</p>
		</Style.ColumnOnchainThirds>

		</Style.OnchainMechanics>

		</Style.Container>
		</>
	)
}


function FAQ() {
	return (
		<>

		<Style.Container>

			<Style.TitleWithSeparator><div>FAQ</div></Style.TitleWithSeparator>
			<Style.FAQ>

			<Style.FAQItem>
			<h3>Who is dailofrog?</h3>
			<p>An onchain art maxi focusing on creating generative art and innovative interactive experiences. Creator of Base's first NFT (and first onchain NFT) <a href={links.tinyBasedFrogs} target="_blank">Tiny Based Frogs</a>, <a href={links.memoryBlocks} target="_blank">Memory Blocks</a> and <a href={links.linkTree} target="_blank">more</a>.</p>
			</Style.FAQItem>

			<Style.FAQItem>
			<h3>Is everything fully onchain?</h3>
			<p>Yes! The entire collection and game mechanics are stored fully onchain in <a href={links.contractFrontEnd} target="_blank">solidity smart contracts</a> on Base -- including all the metadata, pixel data, game mechanics, and the rendering engine.</p>
			<p>That means there is no reliance on any third-party storage solutions (i.e. IPFS, Arweave, etc), everything is fully decentralized and onchain on the Base network.</p>
			</Style.FAQItem>

			<Style.FAQItem>
			<h3>What is the inspiration?</h3>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta nisl felis. Cras non justo sit amet dui blandit sodales non vitae quam. Sed id ultricies libero. Proin auctor nulla vel blandit pharetra. </p>
			</Style.FAQItem>

			<Style.FAQItem>
			<h3>How is the art stored fully onchain?</h3>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta nisl felis. Cras non justo sit amet dui blandit sodales non vitae quam. Sed id ultricies libero. Proin auctor nulla vel blandit pharetra. </p>
			</Style.FAQItem>

			<Style.FAQItem>
			<h3>How is the claim to the collective pool calculated during "Dishonorable Suicide"?</h3>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta nisl felis. Cras non justo sit amet dui blandit sodales non vitae quam. Sed id ultricies libero. Proin auctor nulla vel blandit pharetra. </p>
			</Style.FAQItem>

			<Style.FAQItem>
			<h3>What happens after my Discipline is sacrificed or commits "Dishonorable Suicide"?</h3>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta nisl felis. Cras non justo sit amet dui blandit sodales non vitae quam. Sed id ultricies libero. Proin auctor nulla vel blandit pharetra. </p>
			</Style.FAQItem>

			<Style.FAQItem>
			<h3>What happens to my Discipline during "Honorable Reveal"?</h3>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta nisl felis. Cras non justo sit amet dui blandit sodales non vitae quam. Sed id ultricies libero. Proin auctor nulla vel blandit pharetra. </p>
			</Style.FAQItem>

			<Style.FAQItem>
			<h3>How long does this game last?</h3>
			<p>This deadly game persists as long as there are Disciples alive. When all Disciplines are have been sacrificed but the last survivor, the game is over.</p>
			<p>We intend for the game to last throughout most of Onchain Summer, as the parameters of the number of Disciplines sacrificed and the frequency can be tweaked live.</p>
			</Style.FAQItem>

			<Style.FAQItem>
			<h3>What is "Onchain Summer 2"?</h3>
			<p>Onchain Summer 2 is Base's second annual summer initiative for unleasing creativty on <a href={links.base} target="_blank">Base</a>. We were heavily involved in the inaugural Onchain Summer (2023) and are stoked to be a part of the second coming.</p>
			<p>Read more about Onchain Summer 2 <a href="https://www.base.org/buildersummer" target="_blank">here</a>.</p>
			</Style.FAQItem>

			<Style.FAQItem>
			<h3>Is this game compatible with Smart Wallets?</h3>
			<p>Yes! We are planning to support Coinbase's <a href="https://www.coinbase.com/wallet/smart-wallet" target="_blank">Smart Wallet</a>. Smart wallets are awesome because it simply lives in your browser, no extensions (like Metamask) or app installs needed.</p>
			<p>Our hope is to be able to support Base's effort on helping bring people onchain. </p>
			</Style.FAQItem>

			<Style.FAQItem>
			<h3>Disclaimer</h3>
			<p><em>"End Game 2: Covenant" is an art project and is not intended as an investment opportunity. Participants should be aware that they can lose all the money they put into this project. The associated contract has not been audited and carries inherent risks. We urge all participants to engage with this project responsibly and at their own risk.</em></p>
			</Style.FAQItem>

			<div style={{textAlign:"center"}}>
			<Separator/>
			</div>
			
		
		</Style.FAQ>

		</Style.Container>
		</>
	)
}

function Footer() {
	return (
		<>

		<Style.Container>

			<Style.Footer>
		<p>© {new Date().getFullYear()} Dailofrog Technologies. All Rights Reserved.</p>
		</Style.Footer>

		</Style.Container>
		</>
	)
}

function SectionHero() {
	return (

		<>
			<Style.TopHeader>- Disciples of <img src={imgIcon}/> The Covenant -</Style.TopHeader>
			<Style.HeaderImage src={imgHero}/>
			<br/> <br/> <br/><br/><br/>
         <Style.Section>
      		<Style.HeroContainer >	
      			<Style.ColumnHeroDesc>
					<h2>- End Game 2 -</h2>
      			   <h1>Covenant</h1>
				   <Separator/>
				   <br/><br/>
					<p>In the shrouded halls of darkness, the sacred <Style.TextHighlight>Covenant</Style.TextHighlight> demands a harsh tribute. Disciples are offered up in brutal human sacrifice, their fates sealed by ancient decree. Will you be the final faithful survivor, emerging from the darkness where others have fallen?</p>
					<br/>
					<p>A <Style.TextHighlight>fully onchain</Style.TextHighlight> royale-style social art game on <a href={links.base} target="_blank">Base</a>, built by <a href={links.twitter} target="_blank">dailofrog</a> for <a href={links.base} target="_blank">Onchain Summer 2</a>. Pixels by <a href={links.twitterGoop} target="_blank">goopgoop</a>.</p>
					<br/>
					<br/>
					<br/><br/>
			  		<Style.ResponsiveImage src={imgCovenantRow}/>
					  <br/>
					  <br/>
              <Mint/>
			  
			  	<br/><br/> <br/><br/><br/>
			 	<MintDetails/>
				
      			</Style.ColumnHeroDesc>
      		</Style.HeroContainer>


				<GameDetails/>
				<OnchainMechanics/>
				<FAQ/>
				<Footer/>
       
         </Style.Section>
		 </>
	)
}

function FrontPage() {
  return (
      <>
        <SectionHero/>
      </>
  );
}

export default FrontPage;
