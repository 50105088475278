// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/MEKSans-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/MEKSans-Italic.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Paragraph";   /*Can be any text*/
  src: local("Paragraph"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");
    font-weight: normal;
     font-style: normal;
    font-smooth: never; /* Disables font smoothing */
  -webkit-font-smoothing: none; /* Specific for WebKit browsers */
  -moz-osx-font-smoothing: grayscale; /* Specific for Firefox on macOS */
}

@font-face {
  font-family: "Paragraph";   /*Can be any text*/
  src: local("Paragraph"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
    font-weight: normal;
     font-style: italic;
    font-smooth: never; /* Disables font smoothing */
  -webkit-font-smoothing: none; /* Specific for WebKit browsers */
  -moz-osx-font-smoothing: grayscale; /* Specific for Firefox on macOS */
}

@font-face {
  font-family: "Header";   /*Can be any text*/
  src: local("Header"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");
      font-style: normal;
       font-style: normal;
    font-smooth: never; /* Disables font smoothing */
  -webkit-font-smoothing: none; /* Specific for WebKit browsers */
  -moz-osx-font-smoothing: grayscale; /* Specific for Firefox on macOS */
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB,IAAI,kBAAkB;EAC9C;0DACoD;IAClD,mBAAmB;KAClB,kBAAkB;IACnB,kBAAkB,EAAE,4BAA4B;EAClD,4BAA4B,EAAE,iCAAiC;EAC/D,kCAAkC,EAAE,kCAAkC;AACxE;;AAEA;EACE,wBAAwB,IAAI,kBAAkB;EAC9C;0DACmD;IACjD,mBAAmB;KAClB,kBAAkB;IACnB,kBAAkB,EAAE,4BAA4B;EAClD,4BAA4B,EAAE,iCAAiC;EAC/D,kCAAkC,EAAE,kCAAkC;AACxE;;AAEA;EACE,qBAAqB,IAAI,kBAAkB;EAC3C;0DACoD;MAChD,kBAAkB;OACjB,kBAAkB;IACrB,kBAAkB,EAAE,4BAA4B;EAClD,4BAA4B,EAAE,iCAAiC;EAC/D,kCAAkC,EAAE,kCAAkC;AACxE","sourcesContent":["@font-face {\n  font-family: \"Paragraph\";   /*Can be any text*/\n  src: local(\"Paragraph\"),\n    url(\"./fonts/MEKSans-Regular.woff\") format(\"woff\");\n    font-weight: normal;\n     font-style: normal;\n    font-smooth: never; /* Disables font smoothing */\n  -webkit-font-smoothing: none; /* Specific for WebKit browsers */\n  -moz-osx-font-smoothing: grayscale; /* Specific for Firefox on macOS */\n}\n\n@font-face {\n  font-family: \"Paragraph\";   /*Can be any text*/\n  src: local(\"Paragraph\"),\n    url(\"./fonts/MEKSans-Italic.woff\") format(\"woff\");\n    font-weight: normal;\n     font-style: italic;\n    font-smooth: never; /* Disables font smoothing */\n  -webkit-font-smoothing: none; /* Specific for WebKit browsers */\n  -moz-osx-font-smoothing: grayscale; /* Specific for Firefox on macOS */\n}\n\n@font-face {\n  font-family: \"Header\";   /*Can be any text*/\n  src: local(\"Header\"),\n    url(\"./fonts/MEKSans-Regular.woff\") format(\"woff\");\n      font-style: normal;\n       font-style: normal;\n    font-smooth: never; /* Disables font smoothing */\n  -webkit-font-smoothing: none; /* Specific for WebKit browsers */\n  -moz-osx-font-smoothing: grayscale; /* Specific for Firefox on macOS */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
