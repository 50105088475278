import { WagmiConfig, createConfig, configureChains  } from 'wagmi'
import { mainnet, base, goerli } from 'wagmi/chains'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc'
import { InjectedConnector } from 'wagmi/connectors/injected'
import {useEffect} from 'react'
import { Buffer } from "buffer";
import React from 'react';

import FrontPage from './components/FrontPage'
import AccountPage from './components/AccountPage'
import {chain} from './components/Config'

import './App.css';

import GlobalStyle from './style/GlobalStyle'

import {
  createBrowserRouter,
  RouterProvider,
  useParams 
} from "react-router-dom";

window.Buffer = window.Buffer || Buffer;
 
 const { chains, publicClient, webSocketPublicClient  } = configureChains(
  [chain],
  [
    //alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY }),
    //publicProvider(),

    jsonRpcProvider({
      rpc: (chain) => ({
        http: chain.rpcUrls.public.http[0],
        //http: "https://rpc.ankr.com/blast_testnet_sepolia/13bc3e5c2b4ca20f87bf24426fde9530951c2ee4b658c542f919396d3ffb90f1",
      }),
    }),

  ],
)

const config = createConfig({
  autoConnect: true,
  publicClient,
  connectors: [new InjectedConnector({ chains })],
  webSocketPublicClient
})


const router = createBrowserRouter([
  {
    path: "/",
    element: <FrontPage/>,
  },

  {
    path: "/account",
    element: <AccountPage/>,
  }

  /*{
    path: "*",
    element: <ErrorPage />,
  },*/
]);


function App() {
  return (

    <WagmiConfig config={config}>
      <GlobalStyle />
      <RouterProvider router={router} />
    </WagmiConfig>
  );
}

export default App;
