// src/GlobalStyle.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    //font-family: monospace, sans-serif;
    font-family: 'Paragraph', sans-serif;
    font-size: 20px;
    line-height:1em;
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  :root {

    --black: black;

    --white: white;

    --tan: #EDC8C4;
    --yellow: #FFF963;

    --hr: #b45252;
  }

  body {
    background-color: var(--black);
    color: var(--tan);
    font-weight: normal;

    

  }

  h1, h2, h3, h4 {
    font-family: 'Header', sans-serif;
    color: var(--white);
    font-weight: normal;
  }


  p {
    color: var(--tan);
    line-height: 1.15em;
  }

  a {
    color:var(--yellow);
    text-decoration:none;
  }

  a:hover {
    text-decoration:underline;
  }

  hr {
    border: none;
    margin: 0 0 0.25em 0;
    height: 1px;
    background-color:var(--hr);
  }
`;

export default GlobalStyle;
